import {useAppSelector, useAppThunkDispatch} from "@app/hooks";
import {askForConfirmation} from "@app/ui-state/slice";
import Checkbox from "@components/Checkbox";
import MonthPicker from "@components/Datepicker/MonthPicker";
import Dropdown from "@components/Dropdown";
import FormElement from "@components/FormElement";
import {selectVersionLocked} from "@shared/state/global/slice";
import {changeLastMonthOfActuals, selectTemplateEntities, upsertTemplate} from "@shared/state/templates/slice";
import dayjs from "dayjs";

import {selectSelectedTemplate} from "../state/selectors";
import useStyles from "./styles.jss";

import type {DropdownItem} from "@components/Dropdown";
import type {Template} from "@shared/types/db";

export interface DatePickerTabProps {
  template?: Template;
}

export default function DatePickerTab({template}: DatePickerTabProps) {
  const styles = useStyles();
  const dispatch = useAppThunkDispatch();
  const selectedTemplate = useAppSelector(selectSelectedTemplate);
  const templates = useAppSelector(selectTemplateEntities);
  const isVersionLocked = useAppSelector(selectVersionLocked);

  const actualTemplate = template || selectedTemplate;
  if (!actualTemplate) return null;

  const allTemplates = Object.values(templates).filter((item): item is Template => !!item);

  const handleChange = <K extends keyof typeof actualTemplate.options>(
    key: K,
    value: (typeof actualTemplate.options)[K],
  ) => {
    const templatesImpacted =
      actualTemplate.type === "financial_statement"
        ? allTemplates.filter((template) => template.type === "financial_statement")
        : [actualTemplate];
    const formattedValue = typeof value === "string" ? value.slice(0, 7) : value;
    if (key === "lastMonthOfActuals" && typeof formattedValue === "string") {
      const newActualsEndFormatted = dayjs(formattedValue).format("MMMM YYYY");
      const newForecastStartMonth = dayjs(formattedValue).add(1, "month").format("MMMM YYYY");
      dispatch(
        askForConfirmation({
          title: "Confirm",
          backgroundImage: "/assets/images/ui/modals/confirm/orange-default.svg",
          mainColor: "orange",
          actionButtonText: "Confirm",
          cancelButton: true,
          text: `You're about to update your<br />Actuals through ${newActualsEndFormatted}.<br /><strong>This change is difficult to reverse.</strong><br /><br />
New Forecast will start from ${newForecastStartMonth}.`,
          onConfirm: () => {
            dispatch(
              changeLastMonthOfActuals({
                templateIds: templatesImpacted.map(({id}) => id),
                newLMOA: formattedValue,
              }),
            );
          },
        }),
      );
    } else {
      for (const template of templatesImpacted) {
        dispatch(upsertTemplate({...template, options: {...template.options, [key]: formattedValue}}));
      }
    }
  };

  const handleTimePeriodChange: TimePeriodAliasDropdownProps["onChange"] = ({timePeriod, from, to}) => {
    const datesUpdate = from && to ? {visibleStart: from, visibleEnd: to} : {};
    dispatch(
      upsertTemplate({
        ...actualTemplate,
        options: {...actualTemplate.options, visibleTimePeriod: timePeriod, ...datesUpdate},
      }),
    );
    for (const otherTemplate of allTemplates) {
      if (otherTemplate.id !== actualTemplate.id && actualTemplate.type === "financial_statement") {
        dispatch(
          upsertTemplate({
            ...otherTemplate,
            options: {...otherTemplate.options, visibleTimePeriod: timePeriod, ...datesUpdate},
          }),
        );
      }
    }
  };

  return (
    <div className={styles.datePickerMain}>
      <FormElement
        className={styles.formElement}
        label="Time Period"
        tooltip="Templates::Options::DisplayTimePeriod::TimePeriod"
      >
        <TimePeriodAliasDropdown
          onChange={handleTimePeriodChange}
          selectedTimePeriod={actualTemplate.options.visibleTimePeriod || "custom"}
          lastMonthOfActuals={actualTemplate.options.lastMonthOfActuals}
        />
      </FormElement>
      {actualTemplate.options.visibleTimePeriod === "last_2_months_compare" ? null : (
        <>
          <FormElement
            className={styles.formElement}
            label="From"
            tooltip="Templates::Options::DisplayTimePeriod::From"
          >
            <MonthPicker
              onChange={(date) => handleChange("visibleStart", date || "")}
              value={actualTemplate.options.visibleStart}
              max={actualTemplate.options.end}
              min={actualTemplate.options.start}
            />
          </FormElement>
          <FormElement className={styles.formElement} label="To" tooltip="Templates::Options::DisplayTimePeriod::To">
            <MonthPicker
              onChange={(date) => handleChange("visibleEnd", date || "")}
              value={actualTemplate.options.visibleEnd}
              max={actualTemplate.options.end}
              min={actualTemplate.options.start}
            />
          </FormElement>
        </>
      )}
      {actualTemplate.type === "generic" ? (
        <FormElement
          className={styles.formElement}
          label="Actuals End"
          tooltip="Templates::Options::DisplayTimePeriod::ActualsEnd"
        >
          <MonthPicker
            onChange={(date) => handleChange("lastMonthOfActuals", date || "")}
            value={actualTemplate.options.lastMonthOfActuals}
            max={actualTemplate.options.end}
            min={actualTemplate.options.start}
          />
        </FormElement>
      ) : null}
      <div className={styles.hideRows}>
        <Checkbox
          checked={!actualTemplate.options.hideRowsWithNoValues}
          onClick={() => handleChange("hideRowsWithNoValues", !actualTemplate.options.hideRowsWithNoValues)}
          text="Display rows with no values"
        />
      </div>
      {/* <div className={styles.showSanityChecks}>
        <Checkbox
          checked={!!actualTemplate.options.showSanityChecks}
          onClick={() => handleChange("showSanityChecks", !actualTemplate.options.showSanityChecks)}
          text="Display Sanity Checks"
        />
      </div> */}
    </div>
  );
}

export const timePeriodAliases = {
  this_year: "This Year",
  this_year_to_last_month: "This Year To Last Month",
  this_year_and_next_year: "This Year And Next Year",
  last_year: "Last Year",
  this_month: "This Month",
  last_month: "Last Month",
  last_2_months_compare: "Two Last Months + Comparison Columns",
  custom: "Custom",
} as const;

export function getStaticTimePeriodsForDropdown(): DropdownItem[] {
  return Object.entries(timePeriodAliases).map(([key, value]) => ({
    key,
    value,
  })) as DropdownItem[];
}

export interface TimePeriodAliasDropdownProps {
  selectedTimePeriod: DisplayAsTimePeriod;
  onChange: ({timePeriod, from, to}: {timePeriod: DisplayAsTimePeriod; from?: string; to?: string}) => void;
  lastMonthOfActuals: string;
  disabled?: boolean;
}

export type DisplayAsTimePeriod = keyof typeof timePeriodAliases;

export function TimePeriodAliasDropdown({
  selectedTimePeriod,
  onChange,
  lastMonthOfActuals,
  disabled = false,
}: TimePeriodAliasDropdownProps) {
  const timePeriodDropdownItems = getStaticTimePeriodsForDropdown();

  const handleChange = (item: DropdownItem) => {
    if (item.key === "custom") return onChange({timePeriod: item.key});
    const {from, to} = getDatesFromTimePeriod(
      item.key as Exclude<DisplayAsTimePeriod, "custom">,
      lastMonthOfActuals,
      "days",
    );
    onChange({timePeriod: item.key as DisplayAsTimePeriod, from, to});
  };
  return (
    <Dropdown
      items={timePeriodDropdownItems}
      onSelect={handleChange}
      selectedKey={selectedTimePeriod}
      buttonBold={false}
      buttonFill
      disabled={disabled}
    />
  );
}

export function getDatesFromTimePeriod(
  period: Exclude<DisplayAsTimePeriod, "custom">,
  lastMonthOfActuals: string | null,
  as: "days" | "months" = "months",
) {
  if (!!timePeriodAliases[period]) {
    return getDatesFromTimePeriodAlias(period, lastMonthOfActuals, as);
  } else if (period.includes(":")) {
    const [from, to] = period.split(":");
    return {from, to};
  } else {
    return {from: "", to: ""};
  }
}

export function getDatesFromTimePeriodAlias(
  period: Exclude<DisplayAsTimePeriod, "custom">,
  lastMonthOfActuals: string | null,
  as: "days" | "months" = "months",
): {from: string; to: string} {
  const formatString = as === "months" ? "YYYY-MM" : "YYYY-MM-DD";
  const currentMonth = lastMonthOfActuals ? dayjs(lastMonthOfActuals).add(1, "month") : dayjs();
  switch (period) {
    case "this_year": {
      return {
        from: currentMonth.clone().startOf("year").format(formatString),
        to: currentMonth.clone().endOf("year").format(formatString),
      };
    }
    case "this_year_to_last_month": {
      return {
        from: currentMonth.clone().startOf("year").format(formatString),
        to: dayjs(lastMonthOfActuals).endOf("month").format(formatString),
      };
    }
    case "this_year_and_next_year": {
      return {
        from: currentMonth.clone().startOf("year").format(formatString),
        to: currentMonth.clone().add(1, "year").endOf("year").format(formatString),
      };
    }
    case "this_month": {
      return {
        from: currentMonth.clone().startOf("month").format(formatString),
        to: currentMonth.clone().endOf("month").format(formatString),
      };
    }
    case "last_month": {
      return {
        from: currentMonth.clone().subtract(1, "month").startOf("month").format(formatString),
        to: currentMonth.clone().subtract(1, "month").endOf("month").format(formatString),
      };
    }
    case "last_year": {
      return {
        from: currentMonth.clone().subtract(1, "year").startOf("year").format(formatString),
        to: currentMonth.clone().subtract(1, "year").endOf("year").format(formatString),
      };
    }
    case "last_2_months_compare": {
      return {
        from: dayjs(lastMonthOfActuals).subtract(1, "month").startOf("month").format(formatString),
        to: dayjs(lastMonthOfActuals).endOf("month").format(formatString),
      };
    }
  }
}
