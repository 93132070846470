import {type ClientRootState} from "@app/client-store";
import {createSelector} from "@reduxjs/toolkit";
import {getValueFromCache} from "@shared/data-functions/cache/cache-utilities";
import {NO_DEPARTMENT_NAME} from "@shared/data-functions/formula/formula-utilities";
import {getAllDateKeysBetween} from "@shared/lib/date-utilities";
import {mapEntitiesToIds} from "@shared/lib/entity-functions";
import {isBalance} from "@shared/lib/row-utilities";
import {formatNumber} from "@shared/lib/templates-utilities";
import {isDsError, type DsError} from "@shared/types/alerts";
import {selectDepartmentId, selectScenarioId} from "@state/global/slice";
import {
  selectIntegrationEntities,
  selectQboIntegrationVendors,
  selectQboStatementRowsByIdenfitierMapping,
} from "@state/integrations/slice";
import {selectValuesByRowIdDateKey} from "@state/transaction-items/slice";

import {selectFlattenedOrderingForUi, selectors, selectSelectedTemplate} from "./selectors";
import {getActiveDs, isBlankCell} from "./utils";

import type {Datasource} from "@shared/types/datasources";
import type {Department, Integration, SanityCheck, Template, TemplateRow} from "@shared/types/db";
import type {AlertsState, DatasourcesState} from "@state/entity-adapters";
import type {RowTypeForUI} from "@state/templates/selectors";
import type {FlattenedOrderingItemForUi} from "./selectors";

type TemplateDataMatrixItem = {
  data: {value: number | string | null; formatted: string; dateKey: string; error?: DsError; dsIndex?: number}[];
  row: TemplateRow;
  isTotal: boolean;
  collapsed: boolean;
  departmentId: string | null;
  vendor: string | null;
  valuesAsString: string;
  depth: number;
  empty: boolean;
  hidden: boolean;
  isEmptyHeader: boolean;
  hasChildRows: boolean;
  expanded: boolean;
  uiOrderingItem: FlattenedOrderingItemForUi;
  sanityCheck?: SanityCheck;
  sanityCheckIntegration?: Integration;
  type: RowTypeForUI;
};

export const selectTemplateData = createSelector(
  (state: ClientRootState) => state.templateRows,
  (state: ClientRootState) => state.datasources,
  selectSelectedTemplate,
  selectFlattenedOrderingForUi,
  selectValuesByRowIdDateKey,
  selectScenarioId,
  selectDepartmentId,
  (state: ClientRootState) => state.departments,
  selectQboIntegrationVendors,
  (state: ClientRootState) => state.sanityChecks,
  selectIntegrationEntities,
  selectQboStatementRowsByIdenfitierMapping,
  (state: ClientRootState) => state.alerts,
  selectors.rowsForcedVisible,
  (
    templateRowsState,
    datasourcesState,
    template,
    flattenedOrdering,
    monthlyCache,
    scenarioId,
    globalDepartmentId,
    departmentsState,
    vendorsMapping,
    sanityChecksState,
    integrationsById,
    qboStatementRowsByIdentifier,
    alertsState,
    rowsForcedVisible,
  ) => {
    // time("TemplateDataSelector", "selectTemplateData");

    const dataMatrix: TemplateDataMatrixItem[] = [];

    const templateOrdering = template?.ordering;
    if (!templateOrdering || !scenarioId) return dataMatrix;

    const currentIdStack: string[] = [];

    // keep track on whether the previous row has values (and so could be hidden if the setting is on) to be able to force them visible if children have values
    const previousDepthLevels: {[key: number]: TemplateDataMatrixItem} = {};
    let previousDepth = 0;

    for (const uiOrderingItem of flattenedOrdering) {
      const {
        id,
        expanded,
        rowId,
        collapsed,
        depth,
        hasChildren,
        total,
        departmentId = null,
        emptyHeaderRow,
        vendor,
      } = uiOrderingItem;
      previousDepth = depth;

      if (depth > previousDepth) {
        currentIdStack.push(id);
      } else if (depth < previousDepth) {
        currentIdStack.pop();
      } else {
        // If the depth is the same, replace the last item in the stack with the current item
        currentIdStack[currentIdStack.length - 1] = id;
      }

      const row = templateRowsState.entities[rowId];
      if (!row) continue;

      const department =
        departmentsState.entities[departmentId ?? departmentsState.idsByName[NO_DEPARTMENT_NAME] ?? ""];

      const vendorDisplayName = vendor
        ? vendor === "no_vendor"
          ? "No Vendor"
          : vendorsMapping[vendor]?.displayName ?? "Unknown Vendor"
        : null;

      let rowName = vendorDisplayName ?? (departmentId ? department?.display_name ?? "" : row.display_name);
      let totalOf: TemplateDataMatrixItem | null = null;
      if (total) {
        rowName = `Total ${rowName}`;
        totalOf = previousDepthLevels[depth];
      }

      const rowCells: TemplateDataMatrixItem = {
        data: [
          {
            formatted: rowName,
            value: rowName, // TODO: handle hiring plan rows
            dateKey: "name",
          },
        ],
        row,
        empty: true,
        hidden: !!template.options.hideRowsWithNoValues,
        isTotal: total,
        collapsed,
        departmentId,
        vendor: vendor ?? null,
        valuesAsString: rowName,
        depth,
        hasChildRows: hasChildren,
        isEmptyHeader: !!emptyHeaderRow,
        expanded: !!expanded,
        type: uiOrderingItem.type,
        uiOrderingItem,
      };

      const sanityCheck = mapEntitiesToIds(
        sanityChecksState.entities,
        sanityChecksState.idsByCheckEntityIdentifier[rowId],
      )[0];

      if (
        sanityCheck &&
        !globalDepartmentId &&
        !departmentId &&
        !vendor &&
        !expanded &&
        !collapsed &&
        (total || !hasChildren)
      ) {
        rowCells.sanityCheck = sanityCheck ?? null;
        const integrationId = sanityCheck.against_entity_identifier.split("::")[0];
        if (uiOrderingItem.type !== "sanityCheckDiff")
          rowCells.sanityCheckIntegration = integrationsById[integrationId];
      }

      if (sanityCheck && (uiOrderingItem.type === "sanityCheckDiff" || uiOrderingItem.type === "sanityCheckSource")) {
        const months = getAllDateKeysBetween(template.options.visibleStart, template.options.visibleEnd);

        if (uiOrderingItem.type === "sanityCheckSource") {
          let getValue = (dateKey: string): number | null => null;
          let rowName = rowCells.data[0].value;
          if (sanityCheck.type === "qbo-statement-row") {
            const matchingQboStatementRow = qboStatementRowsByIdentifier[sanityCheck.against_entity_identifier];
            if (matchingQboStatementRow) {
              rowName = matchingQboStatementRow.data.account;
              getValue = (dateKey: string) => matchingQboStatementRow.data.values[dateKey];
            }
          } else if (sanityCheck.type === "cloudberry-row") {
            const matchingRow = templateRowsState.entities[sanityCheck.against_entity_identifier];
            if (matchingRow) {
              const matchingRowHasChildren = (templateRowsState.idsByParentRowId[matchingRow.id]?.length ?? 0) > 0;
              rowName = matchingRow.display_name;
              getValue = (dateKey: string) =>
                getValueFromCache(
                  monthlyCache,
                  matchingRow,
                  null,
                  null,
                  scenarioId,
                  dateKey,
                  isBalance(matchingRow),
                  matchingRowHasChildren,
                );
            }
          }

          rowCells.data[0].value = rowName;

          for (const dateKey of months) {
            const value = getValue(dateKey);

            if (sanityCheck.type === "qbo-statement-row" && dateKey > template.options.lastMonthOfActuals) {
              rowCells.data.push({
                formatted: "",
                value: null,
                dateKey,
              });
              continue;
            }

            const formatted = formatNumber(
              value,
              row.formatting.currency,
              row.formatting.decimals || 0,
              row.formatting.percentage,
            );

            rowCells.data.push({
              formatted,
              value,
              dateKey,
            });
          }
        } else {
          rowCells.data[0].value = "Difference";

          let checkFailing = false;
          for (const [i, dateKey] of months.entries()) {
            if (sanityCheck.type === "qbo-statement-row" && dateKey > template.options.lastMonthOfActuals) {
              rowCells.data.push({
                formatted: "",
                value: null,
                dateKey,
              });
              continue;
            }

            const checkValue = dataMatrix.at(-2)?.data[i + 1]?.value ?? 0;
            const againstValue = dataMatrix.at(-1)?.data[i + 1]?.value ?? 0;

            const value = Number(checkValue) - Number(againstValue);

            const formatted = formatNumber(
              value,
              row.formatting.currency,
              row.formatting.decimals || 0,
              row.formatting.percentage,
            );

            rowCells.data.push({
              formatted,
              value,
              dateKey,
            });

            if (value >= 0.01 || value <= -0.01) checkFailing = true;
          }

          const prevRow = dataMatrix.at(-1);
          if (checkFailing) {
            rowCells.hidden = false;

            if (prevRow) {
              prevRow.empty = false;
              prevRow.hidden = false;
            }
          } else {
            rowCells.empty = true;
            rowCells.hidden = true;
            if (prevRow) {
              prevRow.empty = true;
              prevRow.hidden = true;
            }
          }
        }
      } else {
        // if (row.id === "23892934-fa39-4bf0-afde-fcba4117bbfe" && uiOrderingItem.total) {
        //   debugger;
        // }
        // If this is an empty header row like the header row of the list of departments, we don't want to add any values for it as they will appear in the total row instead

        // const rowDatasources =
        //   !scenarioId || !row
        //     ? []
        //     : mapEntitiesToIds(
        //         datasourcesState.entities,
        //         datasourcesState.idsByProjKey[projKeyOmitNulls(row.id, scenarioId, departmentId, vendor)],
        //       );
        // TODO: do this properly, right now it's not implemented
        const rowDatasources: Datasource[] = [];

        const rowHasValues = getRowValues(
          row,
          scenarioId,
          department,
          uiOrderingItem,
          datasourcesState,
          alertsState,
          template,
          rowCells,
          monthlyCache,
          rowDatasources,
        );

        rowCells.empty = !rowHasValues;
        rowCells.hidden =
          !!template.options.hideRowsWithNoValues && !rowHasValues && !rowsForcedVisible.includes(row.id);

        // If this is the total row at the bottom and the header (the row this total is for) is empty, we want to force it to be visible
        if (!rowCells.empty && rowCells.isTotal && totalOf?.hidden) {
          totalOf.hidden = false;
          // rowCells.empty = true;

          // Force pull values for the "totalOf" row
          totalOf.data = totalOf.data.slice(0, 1);
          getRowValues(
            totalOf.row,
            scenarioId,
            department,
            totalOf.uiOrderingItem,
            datasourcesState,
            alertsState,
            template,
            totalOf,
            monthlyCache,
            rowDatasources,
            true,
          );

          totalOf.hasChildRows = false;
        }
      }

      previousDepthLevels[depth] = rowCells;
      if (previousDepth > depth && previousDepthLevels[previousDepth]) {
        // If the previousDepth is greater than the current level, it means we went back up a level - delete the previousDepthLevel
        delete previousDepthLevels[previousDepth];
      }

      previousDepth = depth;

      // If there are values in this row and the previous level (parent) is marked as being empty, we want to force it to be visible
      if ((!rowCells.empty && depth > 0) || sanityCheck?.passed === false) {
        const parent = previousDepthLevels[depth - 1];
        if (parent?.hidden) {
          parent.hidden = false;
        }
      }

      // If this is a department or vendor row and there's no value, we don't want to add it at all
      // if (departmentId && !emptyHeaderRow && !rowHasValues) continue;
      dataMatrix.push(rowCells);
    }

    // timeEnd("TemplateDataSelector", "selectTemplateData");
    return dataMatrix;
  },
);

export default selectTemplateData;

function getRowValues(
  row: TemplateRow,
  scenarioId: string,
  department: Department | undefined,
  uiOrderingItem: FlattenedOrderingItemForUi,
  datasourcesState: DatasourcesState,
  alertsState: AlertsState,
  template: Template,
  rowCells: TemplateDataMatrixItem,
  monthlyCache: Record<string, number>,
  rowDatasources: Datasource[],
  force?: boolean,
) {
  let rowHasValues = false;

  const months = getAllDateKeysBetween(template.options.visibleStart, template.options.visibleEnd);
  const balance = isBalance(row);
  const {departmentId, vendor, hasChildren, total, collapsed, expanded} = uiOrderingItem;

  for (const dateKey of months) {
    // If this is a cell for which we should not try to retrieve a value
    // (eg. non-parent row when departments are expanded, non-parent department row when vendors are expanded, etc)
    // we just resolve it to an empty cell

    const activeDs = getActiveDs(
      datasourcesState,
      uiOrderingItem,
      dateKey,
      scenarioId,
      row,
      template,
      departmentId ?? null,
    );

    const alertsForDs = mapEntitiesToIds(alertsState.entities, alertsState.idsByDatasourceId[activeDs?.id ?? ""]);

    if (
      !force &&
      !isBalance(row) &&
      isBlankCell(uiOrderingItem, dateKey, scenarioId, datasourcesState, row, template, departmentId ?? null)
    ) {
      rowCells.data.push({
        formatted: "",
        value: null,
        dateKey,
        error: isDsError(alertsForDs[0]) ? alertsForDs[0] : undefined,
      });
      continue;
    }

    const value = getValueFromCache(
      monthlyCache,
      row,
      departmentId,
      vendor,
      scenarioId,
      dateKey,
      balance,
      hasChildren && (total || (!departmentId && collapsed) || (!!departmentId && !expanded)),
    );

    if (!rowHasValues && value) rowHasValues = true;

    const formatted = formatNumber(
      value,
      row.formatting.currency,
      row.formatting.decimals || 0,
      row.formatting.percentage,
    );

    rowCells.data.push({
      formatted,
      value,
      dateKey,
      error: isDsError(alertsForDs[0]) ? alertsForDs[0] : undefined,
    });

    // Add the value to the str key to keep track of whether the values have changed in that row between renders
    rowCells.valuesAsString += `::${formatted}`;
  }

  // If display period is last 2 months + comparison, add the comparison columns
  if (template.options.visibleTimePeriod === "last_2_months_compare") {
    // Last entry in data
    const lastMonthValue = Number(rowCells.data[rowCells.data.length - 1].value ?? 0);

    // Second last entry in data
    const secondLastMonthValue = Number(rowCells.data[rowCells.data.length - 2].value ?? 0);

    const value = lastMonthValue - secondLastMonthValue || null;

    const formatted = formatNumber(
      value,
      row.formatting.currency,
      row.formatting.decimals || 0,
      row.formatting.percentage,
    );

    rowCells.data.push({
      formatted,
      value,
      dateKey: "Change ($)",
    });

    const percentageValue = secondLastMonthValue ? (value ?? 0) / secondLastMonthValue : null;

    const percentageFormatted = formatNumber(
      percentageValue,
      row.formatting.currency,
      row.formatting.decimals || 1,
      true,
    );

    rowCells.data.push({
      formatted: percentageFormatted,
      value: percentageValue,
      dateKey: "Change (%)",
    });
  }
  return rowHasValues;
}
